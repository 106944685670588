import { Carousel, Center, Grid, Skeleton, Stack } from '@lojinha/design-system'
import React from 'react'
import styled from 'styled-components'
import { useWindowProperties } from '../../helpers/useWindowProperties'

const LoadingCard = styled.div<{ isDish?: boolean }>(
  ({ theme, isDish }) => `
    height: ${isDish ? '352px' : '312px'};
    border-radius: 8px;
    overflow: hidden;


    ${theme.media.above('md')} {
      height: ${isDish ? '536px' : '408px'};
    }
  `
)

const LoadingText = styled.div`
  width: ${({ theme }) => theme.space.x128};
  height: ${({ theme }) => theme.space.x16};
`

export const CarouselDisplayLoading = ({ isDish }: { isDish?: boolean }) => {
  const { isMobile } = useWindowProperties()

  const quantityCards = isDish ? 2 : 4
  const slideToShow = isMobile ? quantityCards / 2 : quantityCards

  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid gridColumn={['2 / 24', '2 / 12']} display="block">
          <Stack margin={['x16', 'x24']}>
            <LoadingText>
              <Skeleton />
            </LoadingText>
            <Carousel
              id="display-loading"
              slideToShow={slideToShow}
              constrolsOnVertical={isMobile ? 'bottom' : undefined}
              constrolsOnHorizontal={isMobile ? 'center' : undefined}
            >
              {[...Array(4)].map((_, i) => (
                <LoadingCard key={i} isDish={isDish}>
                  <Skeleton />
                </LoadingCard>
              ))}
            </Carousel>
          </Stack>
        </Grid>
      </Grid>
    </Center>
  )
}
