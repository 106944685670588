import { gql } from '@lojinha/palantir'

export const CMS_CONTAIENRS_FRAGMENT = gql`
  fragment CMSContainerItems on CMSContainer {
    __typename
    id
    size
    layout
    slider
    components {
      ... on CMSComponentContainerImage {
        __typename
        id
        title
        description
        borderRadius
        isVertical
        image {
          url
        }
      }
      ... on CMSComponentContainerBanner {
        id
        title
        imageDesktop {
          url
        }
        imageMobile {
          url
        }
        description
        redirectUrl
      }
      ... on CMSComponentContainerHeader {
        id
        title
        text
        color {
          primary
          background
        }
      }
      ... on CMSComponentContainerProducerAreaCards {
        cards {
          image {
            url
          }
          name
          occupation
          redirectUrl
        }
      }
      ... on CMSComponentContainerText {
        customText
      }
      ... on CMSComponentDeliveryAreaCards {
        cards {
          state
          mainCity
          minorCities
          deliveryMapLink
          takeoffMapLink
        }
      }
      ... on CMSComponentContainerVideo {
        __typename
        id
        title
        description
        url
      }
      ... on CMSComponentContainerCallToAction {
        id
        title
        text
        buttonText
        color {
          background
        }
        link
        image {
          url
        }
      }
      ... on CMSComponentContainerQuote {
        quote
        author
      }
      ... on CMSComponentHighlightStoryHighlightStories {
        id
        highlights {
          title
          image {
            url
          }
          destinationUrl
          distributionCenters {
            externalId
          }
          userProfiles {
            type
          }
        }
      }
      ... on CMSComponentContainerDisplay {
        id
        title
        redirectUrl
        home
        subcategory
        items
      }
      ... on CMSComponentContainerCustomHtml {
        id
        html
        head
        css
        javascript
      }
      ... on CMSComponentContainerButton {
        id
        text
        redirectUrl
        size
        type
        block
        newTab
      }
      ... on CMSComponentContainerAccordion {
        id
        title
        description
        accordionItem {
          title
          text
        }
      }
    }
  }
`

export const DISPLAY_ITEMS_FRAGMENT = gql`
  fragment DisplayItems on IMarketable {
    id
    imageUrl
    name
    kind
    sku
    price
    priceFrom
    unit
    sellingStatus
    seo {
      url
    }
    ... on Product {
      isAvailable(distributionCenterId: $distributionCenterId)
      isAgeRestricted
      weight
      itemTags(maxTags: 2) {
        totalCount
        nodes {
          colorPalette
          content
        }
      }
    }
    ... on Meal {
      isAvailable(distributionCenterId: $distributionCenterId)
      weight
      mainProduct
      subtitle
      itemTags(maxTags: 2) {
        totalCount
        nodes {
          colorPalette
          content
        }
      }
    }
    ... on Kit {
      isAvailable(distributionCenterId: $distributionCenterId)
      meanPricePerDay
      description
      itemTags(maxTags: 2) {
        totalCount
        nodes {
          colorPalette
          content
        }
      }
    }
  }
`

export const PAGE_QUERY = gql`
  query GetPageContent(
    $slug: String!
    $distributionCenterId: String!
    $userProfile: ICMSUserProfiles!
    $limit: Float
  ) {
    page(
      slug: $slug
      distributionCenterId: $distributionCenterId
      userProfile: $userProfile
    ) {
      title
      description
      seo {
        title
        description
      }
      components {
        totalCount
        nodes {
          __typename
          ... on Banner {
            url
            description
          }
          ... on Display {
            id
            name
            isSubcategory
            slug
            kind
            itemsOptimized: marketableItemsOptimized(
              distributionCenterId: $distributionCenterId
              limit: $limit
            ) {
              ...DisplayItems
            }
          }

          ... on HighlightStories {
            count
            stories {
              title
              listPosition
              imageUrl
              content {
                destinationUrl
              }
              onlyOnDistributionCenters
              releaseDate
            }
          }
          ...CMSContainerItems
        }
      }
    }
  }
  ${DISPLAY_ITEMS_FRAGMENT}
  ${CMS_CONTAIENRS_FRAGMENT}
`

export const SPECIAL_PAGE_QUERY = gql`
  query GetSpecialPageContent($slug: String!) {
    specialPage(slug: $slug) {
      title
      url
      banner
      products
      marketables {
        categories
        description
        directions
        distributionCentersNotSelling
        gender
        id
        imageUrl
        isActive
        isAvailable
        isLowCost
        isNew
        itemTags {
          __typename
          nodes {
            __typename
            content
            colorPalette
          }
        }
        kind
        name
        order
        price
        priceFrom
        releaseDateString
        sellingStatus
        sku
        unit
        weight
      }
      scripts
      style
      contentFirstSection
      contentSecondSection
      metaKeywords
      metaDescription
      metaExtra
      itemsSectionTitle
      itemsSectionUrl
    }
  }
`
