import React, { FC } from 'react'
import { useLojinhaContext } from '@lojinha/context'
import { beholder } from '@lojinha/beholder/src'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import { Link } from '@lojinha/vitrine/src/components/link'
import { getBannerImageFromStrapiByUrl } from '@lojinha/helpers'
import { CmsComponentContainerBanner } from '@lojinha/palantir'
import { ImageWrapper, PureImage } from './styles'

interface BannerProps
  extends Omit<
    CmsComponentContainerBanner,
    'id' | 'imageMobile' | 'imageDesktop'
  > {
  imageMobile: string
  imageDesktop: string
}

export const Banner: FC<BannerProps> = ({
  imageMobile,
  imageDesktop,
  title,
  redirectUrl,
  description,
}) => {
  const { isMobile } = useWindowProperties()

  const { isMobileUserAgent } = useLojinhaContext()

  const imageUrl = isMobile || isMobileUserAgent ? imageMobile : imageDesktop

  const { lowQualityUrl, optimizedUrl } = getBannerImageFromStrapiByUrl(
    imageUrl
  )

  const onClick = () => {
    const imageUrlSplited = imageUrl?.split('/') ?? ''
    const trackerLabel = imageUrlSplited[imageUrlSplited.length - 1]

    beholder.shot('structuredEvent', {
      category: 'navigation',
      action: 'click_home_banner',
      label: trackerLabel,
      property: redirectUrl ?? '',
      value: null,
    })
  }

  return (
    <Link
      href={redirectUrl ?? '/'}
      htmlAttrs={{ 'aria-label': description || title }}
    >
      <ImageWrapper onClick={onClick}>
        <PureImage
          src={lowQualityUrl}
          alt={title ?? ''}
          aria-hidden
          loading="lazy"
        />
        <img src={optimizedUrl} alt={title ?? ''} aria-hidden loading="lazy" />
      </ImageWrapper>
    </Link>
  )
}
