import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { normalizeHtmlId } from '../../../helpers/normalizeHtmlId'
import { useWindowProperties } from '../../../helpers/useWindowProperties'

// to remove showVariant
export function useAnchorControl(displayNames: string[], showVariant: boolean) {
  const { asPath } = useRouter()
  const [active, _setActive] = useState('')
  const click = useRef('')
  const ref = useRef<HTMLDivElement>(null)
  const ready = useRef(false)
  const { isMobile } = useWindowProperties()

  const changeUrlAnchor = (anchorId: string) => {
    showVariant &&
      anchorId &&
      window.history.pushState(null, '', `${asPath.split('#')[0]}#${anchorId}`)
  }

  const getAnchorTop = (anchorId: string) => {
    const anchorEl = ref.current?.querySelector(`#${anchorId}`)
    return anchorEl?.getBoundingClientRect().top ?? 0
  }

  const scrollTo = (anchorId: string) => {
    const anchorTop = getAnchorTop(anchorId)
    const newAnchorTop = isMobile
      ? anchorTop + window.scrollY - 120
      : anchorTop + window.scrollY - 160

    window.scrollTo({
      top: newAnchorTop,
      behavior: 'smooth',
    })

    changeUrlAnchor(anchorId)
  }

  const setActive = (anchorId: string) => {
    _setActive(anchorId)
    click.current = anchorId
    scrollTo(anchorId)
  }

  useEffect(() => {
    _setActive(normalizeHtmlId(displayNames[0]))
    setTimeout(() => {
      ready.current = true

      const hasUrlAnchor = asPath.split('#').length > 1
      if (hasUrlAnchor) {
        setActive(asPath.split('#')[1])
      }
    }, 1000)
  }, [])

  const getAnchorSelectedByUserScroll = (names: string[]) => {
    const anchors = names.map(name => {
      const anchorTop = getAnchorTop(normalizeHtmlId(name))
      return {
        name: name,
        top: anchorTop,
      }
    })

    const selectedAnchor = [...anchors].reverse().find(_anchor => {
      return _anchor.top < 200
    })

    return normalizeHtmlId(selectedAnchor?.name ?? names[0])
  }

  useEffect(() => {
    const handleScroll = () => {
      if (ready.current) {
        const newActive = getAnchorSelectedByUserScroll(displayNames)

        const isUserScroll = !click.current

        if (isUserScroll) {
          _setActive(newActive)
          changeUrlAnchor(newActive)
        } else if (click.current === newActive) {
          _setActive(newActive)
          changeUrlAnchor(newActive)
          click.current = ''
        }
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return {
    ref,
    active,
    setActive,
  }
}
