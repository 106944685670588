import React, { FC } from 'react'
import styled from 'styled-components'
import Img, { ImageProps } from 'next/image'
import Link from 'next/link'
import { Text, Box, Button } from '@lojinha/design-system'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import {
  CmsComponentContainerProducerCard,
  CmsUploadFile,
} from '@lojinha/palantir'

const IMAGE_DESKTOP_WIDHT = 227
const IMAGE_DESKTOP_HEIGHT = 303
const IMAGE_MOBILE_WIDTH = 188
const IMAGE_MOBILE_HEIGHT = 252

export interface ProducerCardProps
  extends Omit<CmsComponentContainerProducerCard, '_id' | 'id' | 'image'> {
  image?: Partial<Pick<CmsUploadFile, 'url'>>
}

const Image = styled(Img)<ImageProps>`
  border-radius: 8px;
`

const Anchor = styled.a`
  color: inherit;
  text-decoration: inherit;
`

const ConditionalLink: FC<{ href?: string | null }> = ({ children, href }) =>
  href ? (
    <Link href={href}>
      <Anchor href={href}>{children}</Anchor>
    </Link>
  ) : (
    <>{children}</>
  )

export const ProducerCard: FC<ProducerCardProps> = ({
  image,
  name,
  occupation,
  redirectUrl,
}) => {
  const { isMobile } = useWindowProperties()
  const imageWidth = isMobile ? IMAGE_MOBILE_WIDTH : IMAGE_DESKTOP_WIDHT
  const imageHeight = isMobile ? IMAGE_MOBILE_HEIGHT : IMAGE_DESKTOP_HEIGHT

  return (
    <ConditionalLink href={redirectUrl}>
      <Box marginBottom={'x16'} width={imageWidth} minWidth={imageWidth}>
        {image && (
          <Image
            loader={resolverProps => resolverProps.src}
            src={image.url as string}
            width={imageWidth}
            height={imageHeight}
            objectFit="cover"
          ></Image>
        )}
        <Box paddingTop="x12" paddingBottom="x8">
          <Text as="span" textSize={['x1', 'x2']} isBold>
            {name}
          </Text>
          <Text as="span" textSize={['x1', 'x2']}>
            &nbsp;·&nbsp;
          </Text>
          <Text as="span" textSize={['x1', 'x2']}>
            {occupation}
          </Text>
        </Box>
        {redirectUrl && (
          <Button size="small" isOutlined>
            conhecer
          </Button>
        )}
      </Box>
    </ConditionalLink>
  )
}
