import React, { FC } from 'react'
import styled from 'styled-components'
import { Box, Grid, Text } from '@lojinha/design-system'
import { ColorProps } from '@lojinha/design-system/src/theme'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import {
  CmsColor,
  CmsComponentContainerHeader,
} from '@lojinha/palantir/src/palantirTypes'

const TAB_DESKTOP_HEIGHT = 250
const TAB_MOBILE_HEIGHT = 85

interface HeaderProps
  extends Omit<CmsComponentContainerHeader, '_id' | 'id' | 'color'> {
  color?: CmsColor
}

const Container = styled(Box)<{ backgroundColor?: string | null }>(
  ({ backgroundColor }) => `
  position: relative;
  background-color: ${backgroundColor};
  `
)

const Tab = styled.div<{
  backgroundColor?: string | null
  isMobile?: boolean
}>(
  ({ backgroundColor, isMobile }) => `
  position: absolute;
  width: 100%;
  height: ${(isMobile ? TAB_MOBILE_HEIGHT : TAB_DESKTOP_HEIGHT) + 1}px;
  bottom: -${isMobile ? TAB_MOBILE_HEIGHT : TAB_DESKTOP_HEIGHT}px;
  z-index: -1;
  background-color: ${backgroundColor};
  `
)

export const Header: FC<HeaderProps> = ({ title, text, color }) => {
  const { isMobile } = useWindowProperties()

  return (
    <Container
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={['x48', 'x80']}
      px="x16"
      backgroundColor={color?.background}
    >
      <Grid
        gridTemplateColumns="repeat(12, 1fr)"
        gridGap="x8"
        gridRowGap="x24"
        htmlAttrs={{ style: { width: '100%' } }}
      >
        <Grid gridColumn={['1/13', '3/11']}>
          <Text
            textTransform="uppercase"
            color={color?.primary as ColorProps}
            textSize={['x4', 'x6']}
            textAlign="center"
            isBrand
          >
            {title}
          </Text>
        </Grid>
        <Grid gridColumn={['1/13', '4/10']}>
          <Text
            color={color?.primary as ColorProps}
            textSize="x20"
            textAlign="center"
          >
            {text}
          </Text>
        </Grid>
      </Grid>

      <Tab backgroundColor={color?.background} isMobile={isMobile} />
    </Container>
  )
}
