import { isPageSlug } from '@lojinha/helpers'
import {
  useQuery,
  GetSpecialPageContentQuery,
  GetSpecialPageContentQueryVariables,
} from '@lojinha/palantir'

import { SpecialPage, SpecialPageContentProps } from '../page-content.types'
import { SPECIAL_PAGE_QUERY } from './queries'

export const useSpecialPageContent = ({
  slug = '',
}: {
  slug: string
}): SpecialPageContentProps => {
  const isHome = slug === 'nosso-cardapio' || slug === ''

  const { data, loading } = useQuery<
    GetSpecialPageContentQuery,
    GetSpecialPageContentQueryVariables
  >(SPECIAL_PAGE_QUERY, {
    variables: {
      slug,
    },
    skip: isHome || !isPageSlug(slug),
  })

  return {
    specialPage: data?.specialPage as SpecialPage,
    loading,
  }
}
