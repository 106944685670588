import React, { FC } from 'react'
import { Router } from '../../../router'
import { CMS } from '../../modules/cms'
import { LoadingComponent } from './components/loading'
import { usePageContent } from './hook'
import { NoPageComponent } from './no-page.ui'
import { PageContentUI } from './page-content.ui'

const onEmptyStateClick = () => {
  Router.push('/nosso-cardapio')
}

export const PageContent: FC<{
  pageUrl: string | string[]
}> = ({ pageUrl }) => {
  const {
    loading,
    page,
    fullPath,
    showEmptyState,
    onClickWillDeliver,
    address,
    specialPage,
    isHome,
  } = usePageContent({
    pageUrl,
  })

  if (page) {
    return (
      <PageContentUI
        loading={loading}
        page={page}
        isHome={isHome}
        fullPath={fullPath}
        showEmptyState={showEmptyState}
        onEmptyStateClick={onEmptyStateClick}
        onClickWillDeliver={onClickWillDeliver}
        address={address}
      />
    )
  }

  if (specialPage) {
    return <CMS specialPage={specialPage} />
  }

  if (loading) return <LoadingComponent isHome={isHome} />

  return <NoPageComponent onButtonClick={onEmptyStateClick} />
}
