import { Box, Stack } from '@lojinha/design-system'
import React, { FC } from 'react'
import styled from 'styled-components'
import { CMSPageContent } from '@lojinha/cms-strapi/src/components/page-content'
import { CmsContainer } from '@lojinha/palantir'
import { experimentsIds, useSimpleExperiment } from '@lojinha/optimize'
import { EmptyState } from '../../components/empty-state'
import { t } from '../../dictionary'
import { Display as DisplayOld } from '../display-old'
import { BannerBreadcrumb } from '../../components/banner-breadcrumb'
import { BannerBreadcrumb as BannerBreadcrumbOld } from '../../components/banner-breadcrumb-old'
import { NavigationAnchors } from '../../components/navigation-anchors'
import { MobileBreadcrumb } from '../../components/mobile-breadcrumb'
import { BannerComponent, DisplayComponent, Page } from './page-content.types'
import { PageSeo } from './page-seo'
import { useAnchorControl } from './hook/use-anchor-control'
import { DisplayCtrl } from './components/display-ctrl'

const EmptyStateWrapper = styled(Box)(
  ({ theme }) => `
    ${theme.media.below('md')} {
      padding: ${theme.space.x120} ${theme.space.x0};
    }
  `
)

interface IPageContentUI {
  loading: boolean
  page?: Page | null
  isHome: boolean
  fullPath: string
  showEmptyState: boolean
  onEmptyStateClick: () => void
  onClickWillDeliver: () => void
  address: string | undefined
}

export const PageContentUI: FC<IPageContentUI> = ({
  page,
  isHome,
  fullPath,
  showEmptyState,
  onEmptyStateClick,
}) => {
  const { showVariant } = useSimpleExperiment(experimentsIds.products_listing)

  const containers =
    ((page &&
      page.components.nodes.filter(
        container => container.__typename == 'CMSContainer'
      )) as CmsContainer[]) ?? []

  const displays =
    (page?.components.nodes.filter(
      (container: any) =>
        container.__typename == 'Display' && container.itemsOptimized.length > 0
    ) as DisplayComponent[]) ?? []

  const displayNames = displays.map(display => display.name)
  // to remove showVariant
  const { ref, active, setActive } = useAnchorControl(displayNames, showVariant)

  if (showVariant)
    return (
      <>
        <PageSeo
          seoTitle={page?.seo.title}
          seoDescription={page?.seo.description}
          url={fullPath}
        />

        <Stack margin={['x24', 'x32', 'x40']} noZeroMargin>
          <div ref={ref}>
            {page?.components.nodes.map((component, index) => {
              const componentTypename = component.__typename
              const componentKey = `${componentTypename}_${index}`
              if (componentTypename === 'CMSContainer') {
                return (
                  <CMSPageContent
                    key={componentKey}
                    container={component as CmsContainer}
                  />
                )
              }

              if (componentTypename === 'Banner' && !isHome) {
                return (
                  <>
                    <BannerBreadcrumb
                      component={component as BannerComponent}
                      fullPath={fullPath}
                      key={componentKey}
                    />
                    <NavigationAnchors
                      anchors={displayNames}
                      active={active}
                      setActive={setActive}
                    />
                    <MobileBreadcrumb
                      component={component as BannerComponent}
                      fullPath={fullPath}
                      key={componentKey}
                    />
                  </>
                )
              }

              if (componentTypename === 'Display') {
                if (showEmptyState) return null
                return (
                  <DisplayCtrl
                    displayComponent={component as DisplayComponent}
                    componentTypename={componentTypename}
                    index={index}
                    isHome={isHome}
                    showVariant={showVariant}
                  />
                )
              }

              return null
            })}
          </div>
          {showEmptyState && containers.length <= 0 && (
            <EmptyStateWrapper>
              <EmptyState
                text={t.emptyPageText}
                buttonText={t.emptyPageButton}
                onButtonClick={onEmptyStateClick}
              />
            </EmptyStateWrapper>
          )}
        </Stack>
      </>
    )

  return (
    <>
      <PageSeo
        seoTitle={page?.seo.title}
        seoDescription={page?.seo.description}
        url={fullPath}
      />
      <Stack margin={['x24', 'x32', 'x40']} noZeroMargin>
        {page?.components.nodes.map((component, index) => {
          const componentTypename = component.__typename
          const componentKey = `${componentTypename}_${index}`
          if (componentTypename === 'CMSContainer') {
            return (
              <CMSPageContent
                key={componentKey}
                container={component as CmsContainer}
              />
            )
          }

          if (componentTypename === 'Banner' && !isHome) {
            return (
              <BannerBreadcrumbOld
                component={component as BannerComponent}
                fullPath={fullPath}
                key={componentKey}
              />
            )
          }

          if (componentTypename === 'Display') {
            if (showEmptyState) return null
            const displayComponent = component as DisplayComponent

            const { isSubcategory, name, id } = displayComponent

            return (
              <DisplayOld
                key={id ?? `${componentTypename}_${index}`}
                {...displayComponent}
                isHome={isHome}
                name={isSubcategory ? '' : name}
              />
            )
          }

          return null
        })}
        {showEmptyState && (
          <EmptyStateWrapper>
            <EmptyState
              text={t.emptyPageText}
              buttonText={t.emptyPageButton}
              onButtonClick={onEmptyStateClick}
            />
          </EmptyStateWrapper>
        )}
      </Stack>
    </>
  )
}
