import React from 'react'
import styled from 'styled-components'
import { Stack, Grid, theme, Center } from '@lojinha/design-system'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import {
  CmsContainer,
  IcmsEnumContainerSize,
  IcmsEnumContainerLayout,
} from '@lojinha/palantir/src/palantirTypes'

const cols = {
  [IcmsEnumContainerSize.Fullscreen]: ['1/25', '1/13'],
  [IcmsEnumContainerSize.Fullpage]: ['2/24', '2/12'],
  [IcmsEnumContainerSize.Content]: ['2/24', '4/10'],
}

const stackDirections: Record<
  IcmsEnumContainerLayout,
  'vertical' | 'horizontal'
> = {
  [IcmsEnumContainerLayout.Horizontal]: 'horizontal',
  [IcmsEnumContainerLayout.Vertical]: 'vertical',
}

const SliderStack = styled(Stack)<{ slide?: boolean | null }>(
  ({ slide }) =>
    slide &&
    `
    padding: ${theme.space.x16};
    margin: auto;
    overflow-x: auto;
    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: scroll;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  `
)

export const CMSMainContainer: React.FC<CmsContainer> = ({
  id,
  children,
  size,
  layout,
  slider,
}) => {
  const { isMobile, isTablet } = useWindowProperties()

  const canSlider = slider && (isMobile || isTablet)
  const _layout =
    isMobile && !canSlider
      ? IcmsEnumContainerLayout.Vertical
      : layout ?? IcmsEnumContainerLayout.Horizontal

  const ContainerItem = () => (
    <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
      <Grid gridColumn={cols[size]}>
        <SliderStack
          key={`item${id}`}
          layout={stackDirections[_layout]}
          alignItems={'center'}
          slide={canSlider}
        >
          {children}
        </SliderStack>
      </Grid>
    </Grid>
  )

  if (size === IcmsEnumContainerSize.Fullscreen) return ContainerItem()
  return <Center>{ContainerItem()}</Center>
}
