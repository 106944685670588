import styled from 'styled-components'

export const ImageWrapper = styled.div(
  ({ theme }) => `
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-radius: ${theme.space.x8};
    height: 160px;

    ${theme.media.above('md')} {
      height: 280px;
    }

    > div {
      height: 100%;
      width: 100%;
    }

    & img {
      object-fit: cover;
      vertical-align: middle;
      border-radius: ${theme.space.x8};
      min-height: unset !important;
      height: auto !important;
    }
  `
)

export const PureImage = styled.img`
  position: absolute;
  width: auto;
`
