import React, { FC } from 'react'
import { Accordion, Title, theme, Box } from '@lojinha/design-system'
import { CmsComponentContainerAccordion } from '@lojinha/palantir'
import { css } from 'styled-components'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import { CustomText } from '../custom-text'

const descriptionCustomStyle = css`
  font-size: 14px;
`

export const AccordionList: FC<Omit<CmsComponentContainerAccordion, 'id'>> = ({
  title,
  description,
  accordionItem: items,
}) => {
  const { isMobile } = useWindowProperties()

  return (
    <>
      <Title>{title}</Title>
      <Box
        htmlAttrs={{
          style: {
            marginTop: theme.space.x8,
            marginBottom: theme.space.x16,
          },
        }}
      >
        <CustomText
          customText={description || ''}
          customStyles={descriptionCustomStyle}
        />
      </Box>
      {items.map((item, index) => (
        <Accordion
          key={`acc-${index}`}
          heading={item.title || ''}
          headingSize={isMobile ? theme.fontSizes.x2 : theme.fontSizes.x20}
          htmlAttrs={{
            style: {
              paddingTop: theme.space.x24,
              paddingBottom: theme.space.x24,
              marginTop: theme.space.x0,
              borderBottom: `1px solid ${theme.colors.gray200}`,
            },
          }}
        >
          <Box paddingTop="x20">
            <CustomText customText={item.text || ''} />
          </Box>
        </Accordion>
      ))}
    </>
  )
}
