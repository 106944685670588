import React from 'react'
import { Box, Anchor, Text } from '@lojinha/design-system'
import { theme } from '@lojinha/design-system/src/theme'
import { testId } from '@lojinha/helpers'
import { CrumbProps } from '../types'

export const Crumb = ({
  label,
  route,
  ariaLabel,
  isCurrent = false,
  onCrumbClick,
}: CrumbProps) => {
  return (
    <Box
      as="li"
      key={route}
      display="flex"
      alignItems="center"
      minHeight="48px"
    >
      {!isCurrent ? (
        <Anchor
          isBold
          isUppercased
          textSize="x1"
          color="black"
          onClick={() => onCrumbClick && onCrumbClick(route)}
          htmlAttrs={{
            'aria-label': ariaLabel,
            style: {
              whiteSpace: 'nowrap',
            },
            tabIndex: 0,
          }}
          {...testId(`crumb${route.replace(/\//g, '-')}`)}
        >
          {label}
        </Anchor>
      ) : (
        <Text
          isBold
          textTransform="uppercase"
          textSize="x1"
          color="gray700"
          htmlAttrs={{
            'aria-label': `Página atual: ${label}`,
            'aria-current': 'page',
            style: {
              cursor: 'not-allowed',
              whiteSpace: 'nowrap',
            },
          }}
          {...testId(`crumb${route.replace(/\//g, '-')}`)}
        >
          {label}
        </Text>
      )}
      {!isCurrent && (
        <Text
          as="span"
          color="black"
          isBold
          textSize="x1"
          htmlAttrs={{
            'aria-hidden': true,
            style: { paddingLeft: theme.space.x4 },
          }}
        >
          /
        </Text>
      )}
    </Box>
  )
}
