import { Box, Center, Grid, Stack, Text, theme } from '@lojinha/design-system'
import React, { FC } from 'react'
import { testId } from '@lojinha/helpers'
import { useWindowProperties } from '../../helpers/useWindowProperties'
import { BannerComponent } from '../../modules/page-content/page-content.types'
import { Banner } from '../banner-old'
import { Breadcrumb } from '../breadcrumb-old'
import { useBreadCrumbs } from '../breadcrumb/hooks/crumbs.hook'

export const BannerBreadcrumb: FC<{
  component: BannerComponent
  fullPath: string
}> = ({ component, fullPath }) => {
  const crumbs = useBreadCrumbs(`/${fullPath}`)

  const mobileCrumbs = [...crumbs]
  const lastCrumb = mobileCrumbs.pop()

  const { isMobile } = useWindowProperties()

  if (!component.url) return null

  return (
    <>
      {/* Desktop Banner and Breadcrumb */}
      <Box
        display={['none', 'none', 'flex']}
        aria-hidden={isMobile}
        {...testId('banner-breadcrumb-test-desktop')}
      >
        <Stack
          margin="x32"
          htmlAttrs={{ style: { width: '100%' } }}
          noZeroMargin
        >
          <Banner {...component} />
          <Center
            htmlAttrs={{
              style: { marginBottom: `-${theme.space.x40}` },
            }}
          >
            <Grid gridTemplateColumns="repeat(12, 1fr)">
              <Grid gridColumn="2 / 12" gridRowGap="x0" display="block">
                <Breadcrumb crumbs={crumbs} />
              </Grid>
            </Grid>
          </Center>
        </Stack>
      </Box>

      {/* Mobile Breadcrumb Only, with H1 component */}
      {lastCrumb && (
        <Box
          display={['flex', 'flex', 'none']}
          aria-hidden={!isMobile}
          paddingTop="x32"
          htmlAttrs={{
            style: { marginBottom: `-${theme.space.x32}` },
          }}
          {...testId('banner-breadcrumb-test-mobile')}
        >
          <Stack htmlAttrs={{ style: { width: '100%' } }} noZeroMargin>
            <Grid gridTemplateColumns="repeat(24, 1fr)" gridRowGap="x0">
              <Grid gridColumn={['2 / 24', '3 / 12']} display="block">
                <Breadcrumb crumbs={mobileCrumbs} />
              </Grid>
            </Grid>
            <Grid gridTemplateColumns="repeat(24, 1fr)" gridRowGap="x0">
              <Grid gridColumn={['2 / 24', '3 / 12']} display="block">
                <Text as="h1" isBrand textSize="x3" textTransform="uppercase">
                  {lastCrumb?.label}
                </Text>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      )}
    </>
  )
}
