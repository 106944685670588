import { useLojinhaContext } from '@lojinha/context'
import { LocalizationModalType } from '@lojinha/context/src/types'
import { isPageSlug } from '@lojinha/helpers'
import {
  useQuery,
  GetPageContentQuery,
  GetPageContentQueryVariables,
  IcmsUserProfiles,
} from '@lojinha/palantir'
import { useEffect, useState } from 'react'

import { t } from '../../../dictionary'
import {
  PageComponent,
  DisplayComponent,
  PageContentProps,
  Page,
} from '../page-content.types'
import { PAGE_QUERY } from './queries'
import { useSpecialPageContent } from './special-page'

export const usePageContent = ({
  pageUrl = '',
}: {
  pageUrl: string | string[]
}): PageContentProps => {
  const {
    isFirstBuy = true,
    centerId: distributionCenterId,
    setLocalizationModal,
    address: fullAddress,
  } = useLojinhaContext()

  const slug = typeof pageUrl === 'object' ? pageUrl.join('/') : pageUrl
  const isHome = slug === 'nosso-cardapio' || slug === ''

  const [fallbackPageContent, setFallbackPageContent] = useState<Page | null>()
  const limit = isHome ? 7 : undefined

  const pageQueryResult = useQuery<
    GetPageContentQuery,
    GetPageContentQueryVariables
  >(PAGE_QUERY, {
    variables: {
      slug,
      limit,
      userProfile: isFirstBuy
        ? IcmsUserProfiles.FirstBuy
        : IcmsUserProfiles.Rebuy,
      distributionCenterId: distributionCenterId ?? '',
    },
    skip: !isPageSlug(slug),
  })

  const { specialPage, loading: specialPageLoading } = useSpecialPageContent({
    slug,
  })

  useEffect(() => {
    setFallbackPageContent(undefined)
  }, [pageUrl])

  useEffect(() => {
    if (!pageQueryResult.data) return

    const { page } = pageQueryResult.data

    setFallbackPageContent(page)
  }, [pageQueryResult.data])

  const pageComponents = pageQueryResult.data?.page?.components.nodes

  const showEmptyState = !!pageComponents?.reduce(
    (isEmpty: boolean, component: PageComponent) => {
      if (component.__typename === 'CMSContainer') return false
      if (component.__typename !== 'Display') return isEmpty

      const displayComponent = component as DisplayComponent

      return isEmpty && !displayComponent.itemsOptimized.length
    },
    true
  )

  const onClickWillDeliver = () =>
    setLocalizationModal({ openedModal: LocalizationModalType.Address })

  const pageQueryIsLoading = pageQueryResult.loading && !fallbackPageContent

  return {
    page: pageQueryResult.data?.page || fallbackPageContent,
    specialPage,
    loading: pageQueryIsLoading || specialPageLoading,
    fullPath: slug,
    showEmptyState,
    onClickWillDeliver,
    address: fullAddress && t.singleAddress(fullAddress),
    isHome: isHome,
  }
}
