import { useState } from 'react'
import { useRouter } from 'next/router'

const fetchExperimentVariant = (experimentId: string) => {
  const startTime = Date.now()
  const maxWaitTime = process.env.MAX_WAIT_TIME
    ? parseInt(process.env.MAX_WAIT_TIME)
    : 10000

  return new Promise<string | undefined>(resolve => {
    if (typeof window === 'undefined') {
      resolve(undefined)
    } else {
      const intervalId = setInterval(() => {
        if (window.google_optimize !== undefined) {
          clearInterval(intervalId)
          resolve(window.google_optimize.get(experimentId))
        } else if (Date.now() - startTime > maxWaitTime) {
          clearInterval(intervalId)
          resolve(undefined)
        }
      }, 100)
    }
  })
}

export const useExperiment = (experimentId: string) => {
  const [variant, setVariant] = useState<string>()
  const [loading, setLoading] = useState(true)
  const { query } = useRouter()

  if (
    typeof window !== 'undefined' &&
    window.dataLayer &&
    !window.dataLayer.find(entry => entry?.event === 'optimize.activate')
  ) {
    window.dataLayer.push({ event: 'optimize.activate', payload: {} })
  }

  // avoid re-render problems after loading is done
  if (loading) {
    if (process.env.SITE_ENV === 'production') {
      fetchExperimentVariant(experimentId).then(googleOptimizeResponse => {
        setVariant(googleOptimizeResponse)
        setLoading(false)
      })
    } else {
      // Simulate experiment response
      if (typeof window === 'undefined') {
        setLoading(false)
      } else {
        setTimeout(() => {
          const mockedExperiment =
            typeof query.experiment === 'string' ? query.experiment : undefined
          setVariant(mockedExperiment)
          setLoading(false)
        }, 1000)
      }
    }
  }

  return { variant, loading }
}

export const useSimpleExperiment = (experimentId: string) => {
  const experiment = useExperiment(experimentId)

  return {
    showVariant: experiment.variant === '1',
    loading: experiment.loading,
  }
}
