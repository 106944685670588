import { MarketableKind } from '@lojinha/palantir'
import { DisplayItems } from '../../modules/page-content/page-content.types'

export type DisplayProps = {
  id: string
  items: DisplayItems[]
  name: string
  slug?: string
  originList: OriginList
  onItemClick: OnItemClickType
  onRenderItem?: OnRenderItemType
}

export type OnRenderItemType = ({
  id,
  name,
  positionInList,
  listName,
  isAvailable,
  price,
}: {
  id: string
  name: string
  positionInList: number
  listName: string
  isAvailable: boolean
  price: number
}) => void

export type OnItemClickType = ({
  id,
  name,
  positionInList,
  isAvailable,
  price,
  originList,
}: {
  id: string
  name: string
  positionInList: number
  isAvailable: boolean
  price: number
  originList: OriginList
}) => void

export type CarouselDiplayProps = DisplayProps & {
  showLastCard: boolean
  kind?: MarketableKind
  onArrowSlideClick: ({
    name,
    direction,
  }: {
    name: string
    direction: 'left' | 'right'
  }) => void
  onSeeAllClick: ({
    name,
    isHeader,
  }: {
    name: string
    isHeader?: boolean
  }) => void
  onRenderItem: OnRenderItemType
  onItemClick: OnItemClickType
  originList: OriginList
}

export type DisplayKitPresentationProps = {
  title: string
  originList: OriginList
  items: DisplayItems[]
  onItemClick: OnItemClickType
  onRenderItem: OnRenderItemType
  onLinkClick?: () => void
  description?: string
  slug?: string
}

export type CardListProps = DisplayProps & {
  kind: MarketableKind
}

export enum OriginList {
  CAROUSEL = 'carrossel',
  LIST = 'lista',
  CATEGORY = 'categoria',
}
