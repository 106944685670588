import React, { FC } from 'react'
import { Box, Text } from '@lojinha/design-system'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import { CmsComponentContainerQuote } from '@lojinha/palantir/src/palantirTypes'

export const Quote: FC<Omit<CmsComponentContainerQuote, '_id' | 'id'>> = ({
  quote,
  author,
}) => {
  const { isMobile } = useWindowProperties()
  return (
    <Box
      borderStyle="solid"
      borderLeftWidth={2}
      borderRightWidth={0}
      borderBottomWidth={0}
      borderTopWidth={0}
      padding={isMobile ? 'x24' : 'x48'}
      borderColor="gray200"
    >
      <Text
        isBrand
        color={'black'}
        textSize={isMobile ? 'x2' : 'x4'}
        textTransform={'uppercase'}
      >
        "{quote}"
      </Text>
      <Box paddingTop={'x16'}>
        <Text color={'black'} textSize={isMobile ? 'x14' : 'x2'}>
          {author}
        </Text>
      </Box>
    </Box>
  )
}
