import React from 'react'
import { Box, Stack } from '@lojinha/design-system'

import { CmsContainer } from '@lojinha/palantir/src/palantirTypes'
import { CMSComponents } from '../../cmsComponents'
import { CMSMainContainer } from '../container'

export const CMSPageContent: React.FC<{
  container: CmsContainer
}> = ({ container }) => {
  return (
    <Stack margin={['x48', 'x80']} key={`container-${container.id}`}>
      <CMSMainContainer {...container}>
        {container?.components?.map((component, index) => {
          if (!component?.__typename) return

          const containerFiltered = CMSComponents[component?.__typename](
            component,
            container
          )
          const key = `container-${index}-${component.id}`

          if (
            index == 0 &&
            component?.__typename == 'CMSComponentContainerBanner'
          ) {
            return (
              <Box key={key} paddingTop={['x16', 'x64']}>
                {containerFiltered}
              </Box>
            )
          }
          return <React.Fragment key={key}>{containerFiltered}</React.Fragment>
        })}
      </CMSMainContainer>
    </Stack>
  )
}
