import React, { FC } from 'react'
import { CmsComponentContainerDisplay, MarketableKind } from '@lojinha/palantir'
import { CarouselDisplay } from '@lojinha/vitrine/src/components/carousel-display'
import { ListDisplay } from '@lojinha/vitrine/src/components/list-display'
import { KitPresentation } from '@lojinha/vitrine/src/modules/display/kit-presentation'
import {
  onArrowSlideClick,
  onItemClick,
  onRenderItem,
  onSeeAllClick,
} from '@lojinha/vitrine/src/modules/display/logic'
import { OriginList } from '@lojinha/vitrine/src/modules/display/types'
import { t } from '@lojinha/vitrine/src/dictionary'

export const Display: FC<
  Omit<CmsComponentContainerDisplay, '_id' | 'display'>
> = ({ id, title, redirectUrl, home, subcategory, items }) => {
  if (!items?.length) return null

  const kind = items[0].kind.toUpperCase()
  const isKit = kind === MarketableKind.Kit
  const kindMealOrProduct =
    kind === MarketableKind.Product || kind === MarketableKind.Meal

  if (isKit && home) {
    return (
      <KitPresentation
        title={t.kitTitle}
        description={t.kitDescription}
        items={items}
        slug={redirectUrl || ''}
        onItemClick={onItemClick}
        onRenderItem={onRenderItem}
        originList={OriginList.LIST}
      />
    )
  }

  if (isKit || subcategory) {
    return (
      <ListDisplay
        id={id}
        items={items}
        name={title || ''}
        slug={redirectUrl || ''}
        kind={kind}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={OriginList.CATEGORY}
      />
    )
  }

  if (kindMealOrProduct) {
    return (
      <CarouselDisplay
        id={id}
        showLastCard={items.length === 7}
        items={items}
        name={title || ''}
        slug={redirectUrl || ''}
        onArrowSlideClick={onArrowSlideClick}
        onSeeAllClick={onSeeAllClick}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={OriginList.CAROUSEL}
        kind={kind}
      />
    )
  }

  return null
}
