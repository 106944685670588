import { Box, Stack } from '@lojinha/design-system'
import React, { FC } from 'react'
import { testId } from '@lojinha/helpers'
import { useWindowProperties } from '../../helpers/useWindowProperties'
import { BannerComponent } from '../../modules/page-content/page-content.types'
import { Banner } from '../banner'
import { useBreadCrumbs } from '../breadcrumb/hooks/crumbs.hook'

export const BannerBreadcrumb: FC<{
  component: BannerComponent
  fullPath: string
}> = ({ component, fullPath }) => {
  const crumbs = useBreadCrumbs(`/${fullPath}`)

  const { isMobile } = useWindowProperties()

  if (!component.url) return null

  return (
    <>
      {/* Desktop Banner and Breadcrumb */}
      <Box
        display={['none', 'none', 'flex']}
        aria-hidden={isMobile}
        {...testId('banner-breadcrumb-test-desktop')}
      >
        <Stack
          margin="x32"
          htmlAttrs={{ style: { width: '100%' } }}
          noZeroMargin
        >
          <Banner {...{ ...component, crumbs }} />
        </Stack>
      </Box>
    </>
  )
}
