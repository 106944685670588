import { Stack } from '@lojinha/design-system'
import React from 'react'
import { Router } from '../../../router'
import { Props } from './types'
import { Crumb } from './crumb'

const onCrumbClick = (route: string) => Router.push(route)

export const Breadcrumb = ({ crumbs }: Props) => {
  return (
    <nav aria-label="Breadcrumb">
      <Stack
        as="ol"
        layout="horizontal"
        margin="x4"
        htmlAttrs={{ style: { listStyle: 'none' } }}
      >
        {crumbs?.map(item => (
          <Crumb
            key={item.route}
            label={item.label}
            route={item.route}
            ariaLabel={item.ariaLabel}
            isCurrent={item.isCurrent}
            onCrumbClick={onCrumbClick}
          />
        ))}
      </Stack>
    </nav>
  )
}
