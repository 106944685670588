import React, { FC, useEffect } from 'react'
import Head from 'next/head'
import { Stack } from '@lojinha/design-system'
import { MarketableKind } from '@lojinha/palantir'
import { BannerBreadcrumb } from '../../components/banner-breadcrumb'
import { PageSeo } from '../../modules/page-content/page-seo'
import {
  BannerComponent,
  DisplayItems,
  SpecialPage,
} from '../../modules/page-content/page-content.types'
import {
  onArrowSlideClick,
  onItemClick,
  onRenderItem,
  onSeeAllClick,
} from '../../modules/display/logic'
import { OriginList } from '../../modules/display/types'
import { CarouselDisplay } from '../../components/carousel-display'

export const CMS: FC<{
  specialPage: SpecialPage
}> = specialPage => {
  const data = specialPage.specialPage

  const marketables = data.marketables
  const items = marketables?.map(marketable => {
    return {
      key: marketable.id,
      id: marketable.id,
      imageUrl: marketable.imageUrl,
      name: marketable.name,
      kind: marketable.kind,
      sku: marketable.sku,
      price: marketable.price,
      priceFrom: marketable.priceFrom,
      unit: marketable.unit,
      sellingStatus: marketable.sellingStatus,
      seo: {
        url: marketable.seo?.url || '',
      },
      isAvailable: marketable.isAvailable,
      itemTags: marketable.itemTags,
      weight: marketable.weight,
      description: marketable.description,
    }
  }) as DisplayItems[]

  useEffect(() => {
    document.head.insertAdjacentHTML('afterend', data.metaExtra || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://stackpath.bootstrapcdn.com" />
        <link
          rel="preload"
          as="script"
          href="https://code.jquery.com/jquery-3.3.1.slim.min.js"
        />
        <link
          rel="preload"
          as="script"
          href="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js"
        />
        <link
          rel="preload"
          as="script"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"
        />
        <link
          rel="preload"
          as="style"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
        />
        <link
          rel="preload"
          as="style"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap-grid.min.css"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js"
          integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"
          integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy"
          crossOrigin="anonymous"
        ></script>
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
          crossOrigin="anonymous"
        />
        <script defer>{`${data.scripts}`}</script>
      </Head>
      <PageSeo
        seoTitle={data.title}
        seoDescription={data.metaDescription}
        url={data.url}
      />

      <Stack margin={['x24', 'x32', 'x40']} noZeroMargin>
        <BannerBreadcrumb
          component={{ title: data.title, url: data.banner } as BannerComponent}
          fullPath={data.url || ''}
        />
        <div
          dangerouslySetInnerHTML={{ __html: data.contentFirstSection || '' }}
          css={`
            ${data.style}
          `}
        />
        {marketables?.length !== 0 && (
          <CarouselDisplay
            id={data.url}
            items={items}
            name={data.itemsSectionTitle}
            onArrowSlideClick={onArrowSlideClick}
            onSeeAllClick={onSeeAllClick}
            onItemClick={onItemClick}
            onRenderItem={onRenderItem}
            originList={OriginList.CAROUSEL}
            showLastCard={false}
            kind={MarketableKind.Product}
            slug={data.itemsSectionUrl}
          />
        )}
        <div
          dangerouslySetInnerHTML={{ __html: data.contentSecondSection || '' }}
        />
      </Stack>
    </>
  )
}
