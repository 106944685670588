import React, { FC } from 'react'
import { Box, Badge, Stack, Text, Button } from '@lojinha/design-system'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import { CmsComponentContainerDeliveryAreaCard } from '@lojinha/palantir/src/palantirTypes'

type DeliveryAreaCardProps = Omit<
  CmsComponentContainerDeliveryAreaCard,
  '_id' | 'id'
>

export const DeliveryAreaCard: FC<DeliveryAreaCardProps> = ({
  state,
  mainCity,
  minorCities,
  deliveryMapLink,
  takeoffMapLink,
}) => {
  const { isMobile, isDesktop } = useWindowProperties()
  const topAndBottomSpacing = () => {
    if (isMobile) {
      return '24px'
    } else {
      return isDesktop ? '40px' : '24px'
    }
  }

  return (
    <Box
      borderStyle="solid"
      borderWidth={1}
      borderColor="gray200"
      hasRoundedCorner={true}
      height={isMobile ? '188px' : '247px'}
      px={isMobile ? 'x24' : 'x40'}
      htmlAttrs={{
        style: { position: 'relative', backgroundColor: '#FFFFFF' },
      }}
    >
      <Stack
        layout="horizontal"
        htmlAttrs={{
          style: {
            marginTop: `${topAndBottomSpacing()}`,
          },
        }}
      >
        <Badge text={state} ariaLabel={state} />
      </Stack>
      <Text
        isBrand
        textTransform="uppercase"
        textSize={isMobile ? 'x2' : 'x3'}
        htmlAttrs={{ style: { marginTop: `${isMobile ? '8px' : '16px'}` } }}
        as={isMobile ? 'h4' : 'h3'}
      >
        {mainCity},
      </Text>
      <Text
        color="gray700"
        textSize="x14"
        htmlAttrs={{ style: { marginTop: '8px' } }}
      >
        {minorCities}
      </Text>
      <Box
        htmlAttrs={{
          style: {
            position: 'absolute',
            bottom: `${topAndBottomSpacing()}`,
          },
        }}
      >
        {deliveryMapLink && (
          <a href={deliveryMapLink} target="_blank" rel="noreferrer">
            <Button size="small" isOutlined>
              Entregar
            </Button>
          </a>
        )}
        {takeoffMapLink && (
          <Box display="inline" marginLeft="x8">
            <a href={takeoffMapLink} target="_blank" rel="noreferrer">
              <Button size="small" isLink>
                Retirar
              </Button>
            </a>
          </Box>
        )}
      </Box>
    </Box>
  )
}
