import React from 'react'
import { Display } from '../../../display'
import { CategoryDisplay } from '../../../display/category-display'
import { DisplayComponent } from '../../../page-content/page-content.types'

export const DisplayCtrl = ({
  displayComponent,
  componentTypename,
  index,
  isHome,
  showVariant,
}: {
  displayComponent: DisplayComponent
  componentTypename: string
  index: number
  isHome: boolean
  showVariant: boolean
}) => {
  const { isSubcategory, name, id } = displayComponent
  const key = id ?? `${componentTypename}_${index}`
  const displayName = isSubcategory ? '' : name

  if (isHome) {
    return (
      <Display
        key={key}
        {...displayComponent}
        isHome={isHome}
        name={displayName}
      />
    )
  }

  return (
    <CategoryDisplay
      key={key}
      {...displayComponent}
      name={name}
      isFirstDisplay={index === 1}
      showVariant={showVariant}
    />
  )
}
