import React, { FC } from 'react'
import { getBannerImageFromStrapiByUrl } from '@lojinha/helpers'
import { CmsComponentContainerImage } from '@lojinha/palantir/src/palantirTypes'
import { useWindowProperties } from '@lojinha/vitrine/src/helpers/useWindowProperties'
import { ImageWrapper, PureImage } from './styles'

interface ImageProps
  extends Omit<CmsComponentContainerImage, '_id' | 'id' | 'image'> {
  image: string
}

export const Image: FC<ImageProps> = ({
  image,
  title,
  isVertical,
  borderRadius: radius,
}) => {
  const { lowQualityUrl, optimizedUrl } = getBannerImageFromStrapiByUrl(image)
  const { isMobile } = useWindowProperties()

  const borderRadius = radius ? 8 : 0
  const mobileWidth = isMobile && '100%'
  const width = isVertical ? '70%' : '100%'

  return (
    <ImageWrapper style={{ borderRadius, width: mobileWidth || width }}>
      <PureImage
        src={lowQualityUrl}
        alt={title ?? ''}
        aria-hidden
        loading="lazy"
        style={{ borderRadius }}
      />
      <img
        src={optimizedUrl}
        alt={title ?? ''}
        aria-hidden
        loading="lazy"
        style={{ borderRadius }}
      />
    </ImageWrapper>
  )
}
