import React, { FC } from 'react'
import { Box, Center, Grid, Text } from '@lojinha/design-system'
import { MarketableKind } from '@lojinha/palantir'
import { KitCardList } from '../../components/card-list/kit'
import { MealCardList } from '../../components/card-list/meal'
import { ProductCardList } from '../../components/card-list/product'
import { CardListProps, OriginList } from '../../modules/display/types'

export const ListDisplay: FC<CardListProps | undefined> = ({
  name,
  items,
  kind,
  originList,
  onRenderItem,
  onItemClick,
}: CardListProps) => {
  if (items.length === 0) return null

  const isMeal = kind === MarketableKind.Meal
  const isKit = kind === MarketableKind.Kit

  const ListByMarketableKind = () => {
    if (isKit) {
      return (
        <KitCardList
          items={items}
          originList={OriginList.LIST}
          onItemClick={onItemClick}
          onRenderItem={onRenderItem}
        />
      )
    }

    if (isMeal) {
      return (
        <MealCardList
          items={items}
          onRenderItem={onRenderItem}
          onItemClick={onItemClick}
          originList={originList}
        />
      )
    }

    return (
      <ProductCardList
        items={items}
        onRenderItem={onRenderItem}
        onItemClick={onItemClick}
        originList={originList}
      />
    )
  }

  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid gridColumn={['2 / 24', '2 / 12']}>
          <Box display={['none', 'none', 'block']}>
            <Text isBrand textTransform="uppercase">
              {name}
            </Text>
          </Box>
          <ListByMarketableKind />
        </Grid>
      </Grid>
    </Center>
  )
}
