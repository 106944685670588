import React, { FC } from 'react'
import {
  IcmsEnumComponentcontainerbuttonSize,
  IcmsEnumComponentcontainerbuttonType,
  CmsComponentContainerButton,
} from '@lojinha/palantir/src/palantirTypes'
import { Button as DesignSystemButton } from '@lojinha/design-system'

const getSize = (size?: IcmsEnumComponentcontainerbuttonSize | null) => {
  switch (size) {
    case IcmsEnumComponentcontainerbuttonSize.Large:
      return 'large'
    case IcmsEnumComponentcontainerbuttonSize.Small:
      return 'small'
    default:
      return undefined
  }
}

const getTypeProps = (type?: IcmsEnumComponentcontainerbuttonType | null) => {
  switch (type) {
    case IcmsEnumComponentcontainerbuttonType.Link:
      return { isLink: true }
    case IcmsEnumComponentcontainerbuttonType.Outlined:
      return { isOutlined: true }
    default:
      return {}
  }
}

export const Button: FC<Omit<CmsComponentContainerButton, '_id' | 'id'>> = ({
  text,
  redirectUrl,
  size,
  type,
  block,
  newTab,
}) => (
  <a
    href={redirectUrl ?? ''}
    target={newTab ? '_blank' : '_self'}
    rel="noreferrer"
  >
    <DesignSystemButton
      {...getTypeProps(type)}
      size={getSize(size)}
      isBlock={block}
    >
      {text}
    </DesignSystemButton>
  </a>
)
